<template>
  <article class="box">
    <h1 class="page-title">
      {{ localDocumentTitle }}
    </h1>

    <VCard>
      <VCardTitle>
        <VTextField
          v-model="search"
          append-icon="search"
          :label="$t('search.one')"
          single-line
          hide-details
        />
      </VCardTitle>
      <VDataTable
        :headers="headers"
        :items="records"
        :loading="loading"
        item-key="key"
        :search="search"
        :page.sync="page"
        @update:page="updatePage"
      >
        <template #[`item.started_at`]="{ item }">
          {{ formatDate(item.started_at) }}
        </template>

        <template #[`item.completed_at`]="{ item }">
          {{ formatDate(item.completed_at) }}
        </template>

        <template #[`item.course_title`]="{ item }">
          <RouterLink
            v-if="item.is_started && (item.order_status.code === 'training' || item.order_status.code === 'training_complete')"
            :to="`/sessions/${item.id}`"
          >
            {{ item.course_title }}
          </RouterLink>
          <span v-else>
            {{ item.course_title }}
          </span>
        </template>

        <template #[`item.status`]="{ item }">
          {{ item.status.title }}
        </template>

        <template #[`item.certificate`]="{ item }">
          <a
            v-if="item.certificate_id"
            :href="`/certificates/${item.certificate_id}/content?_fmt=html`"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ item.certificate_title }}
          </a>
        </template>
      </VDataTable>
    </VCard>
  </article>
</template>

<script>
import { mapGetters } from 'vuex'

import { getUserBook } from '@/api'
import documentTitle from '@/mixins/documentTitle'
import { UDate } from '@/utils/date'
import * as getters from '@/store/getters/types'

export default {
  name: 'TheHistory',
  mixins: [documentTitle],

  data () {
    return {
      documentTitle: 'students_record_book.one',
      loading: true,
      initialRecords: [],
      search: '',
      page: 1
    }
  },

  computed: {
    ...mapGetters({
      account: getters.ACCOUNT
    }),

    headers () {
      return [
        {
          text: this.$t('training_started_at.one'),
          value: 'started_at'
        },
        {
          text: this.$t('training_completed_at.one'),
          value: 'completed_at'
        },
        {
          text: this.$t('courses.one'),
          value: 'course_title',
          sortable: false
        },
        {
          text: this.$t('marks.one'),
          value: 'mark',
          sortable: false
        },
        {
          text: this.$t('statuses.one'),
          value: 'status',
          sort: (a, b) => a.title.toLocaleLowerCase().localeCompare(b.title.toLocaleLowerCase())
        },
        {
          text: this.$t('certificates.one'),
          value: 'certificate',
          sortable: false
        }
      ]
    },

    records () {
      return (this.initialRecords || []).map((record, idx) => {
        record.key = idx
        return record
      })
    }
  },

  created () {
    this.register()
  },

  methods: {
    formatDate: UDate.formatDate,
    async register () {
      const { records } = await getUserBook(this.account.id)
      this.initialRecords = records || []
      this.loading = false
      this.page = parseInt(this.$route.query.page || this.page)
    },

    updatePage (page) {
      const query = Object.assign({}, this.$route.query, {
        page
      })
      this.$router.replace({ query }).catch(() => {})
    }
  }
}
</script>
